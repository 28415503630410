<template>
  <div class="equipment-overhaul-wrapper">
    <van-nav-bar
      title="检验周期"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-overhaul-form">
      <span>共{{ total }}条数据</span>
    </div>
    <div class="equipment-overhaul-box">
      <van-empty
        v-if="!loading && list.length == 0"
        :image="emptyImage"
        description="暂无数据"
      />
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div v-for="item in list" :key="item.id" class="overhaul-item">
          <span class="overhaul-interval"
            >次/<i>{{ item.planOwnership }}</i
            >{{ item.periodUnitName }}</span
          >
          <div>
            <div class="overhaul-title">{{ item.project }}</div>
            <div class="overhaul-bottom">
              <span class="overhaul-mode">{{ item.modeName }}</span>
              <span class="overhaul-lastdate"
                >最近一次时间:{{ item.latestDate }}</span
              >
            </div>
          </div>
          <van-divider />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getEquipmentOverhaulPlans } from "@/api/basics/equipmentRx";
import equipmentMixin from "./equipmentMixin";

export default {
  mixins: [equipmentMixin],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: {
        page: 1,
        size: 10
      },
      list: [],
      total: 0,
      loading: false,
      finished: false
    };
  },
  created() {},
  methods: {
    onLoad() {
      this.getData();
    },
    getData() {
      this.getEquipmentOverhaulPlans();
    },
    getEquipmentOverhaulPlans() {
      this.loading = true;
      getEquipmentOverhaulPlans(this.id, this.query)
        .then(res => {
          console.log("res", res);
          this.loading = false;
          this.list = this.list.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.total = res.total;
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-overhaul-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-overhaul-form {
    height: 56px;
    border-bottom: 1px solid #e1e3e8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    color: #999;
  }
  .equipment-overhaul-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding-top: 14px;
    .overhaul-item {
      position: relative;
      padding: 0 16px;
      .overhaul-interval {
        height: 63px;
        width: 63px;
        display: inline-block;
        background-color: #1676ff;
        color: #fff;
        text-align: center;
        line-height: 63px;
        border-radius: 50%;
      }
      .overhaul-bottom {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        align-items: center;
        .overhaul-mode {
          background-color: rgba(254, 119, 11, 0.1);
          color: #fe770b;
          padding: 4px 8px;
          border-radius: 2px;
        }
        .overhaul-lastdate {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
