import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const api = apiPath.basics;
const equipment = apiPath.equipment;
const psm = apiPath.psm;
const inspection = apiPath.inspection;

/**
 * 企业权限
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageEquipmentSimple(params) {
  return axios.get(equipment + "/page/equipment/manager/simpleInfo", {
    params
  });
}

// 获取设备详情
export const getEquipmentInfo = params => {
  return axios.get(`${equipment}/info/equipment/manager/${params.id}`, {
    params
  });
};
// 获取对应的下拉type=4:文档类型
export const getEquipmentTips = type => {
  return axios.get(`${equipment}/select/equipment/tips?type=${type}`);
};

// 获取设备文档
export const getEquipmentDocumentList = (id, params) => {
  return axios.get(`${equipment}/page/equipment/document`, {
    params: {
      page: 1,
      size: 1000,
      sort: "id_desc",
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};
// 获取故障记录
export const getEquipmentFaultList = (id, params) => {
  return axios.get(`${equipment}/page/device/faults`, {
    params: {
      page: 1,
      size: 1000,
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};
// 获取关联辅件记录
export const getEquipmentSubsidiaryList = (id, params) => {
  return axios.get(`${equipment}/page/equipment/subsidiary`, {
    params: {
      page: 1,
      size: 1000,
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};
// 获取检定计划
export const getEquipmentVerificationPlans = (id, params) => {
  return axios.get(`${equipment}/page/verification`, {
    params: {
      page: 1,
      size: 1000,
      sort: "ctime_desc",
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};
// 获取检修计划
export const getEquipmentOverhaulPlans = (id, params) => {
  return axios.get(`${equipment}/page/equipment/cyclecheck`, {
    params: {
      page: 1,
      size: 1000,
      sort: "ctime_desc",
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};
// 获取润滑保养计划
export const getEquipmentMaintainPlans = (id, params) => {
  return axios.get(`${equipment}/page/maintain/plans`, {
    params: {
      page: 1,
      size: 1000,
      sort: "ctime_desc",
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};
// 获取检修记录
export const getEquipmentOverhaulRecord = (id, params) => {
  return axios.get(`${equipment}/page/equipOverhualExec/new/getList`, {
    params: {
      size: 40,
      sort: "ctime_desc",
      equipmentRecordId: id,
      ...params
    }
  });
};
// 获取润滑保养记录
export const getEquipmentMaintainverhaulRecord = (id, params) => {
  return axios.get(`${equipment}/page/equipMaintenanExec/getList`, {
    params: {
      size: 40,
      equipmentRecordId: id,
      ...params
    }
  });
};
// 获取巡检记录
export const getEquipmentPatrolRecord = (id, params) => {
  return axios.get(`${inspection}/page/app/patrol-records`, {
    params: {
      size: 40,
      equipmentId: id,
      ...params
    }
  });
};
// 获取检定记录
export const getEquipmentVerifyRecord = (id, params) => {
  return axios.get(`${equipment}/page/equipVerification/getList`, {
    params: {
      size: 40,
      equipmentRecordId: id,
      ...params
    }
  });
};
// 获取点检记录
export const getPointCheckRecord = params => {
  return axios.get(`${inspection}/page/app/patrol-records`, {
    params: {
      size: 40,
      ...params
    }
  });
};
// 提交设备故障
export const commitDeviceFault = params => {
  return axios.post(`${equipment}/commit/device/faults`, params);
};

// 获取故障类型
export const getFaultType = params => {
  return axios.get(`${equipment}/dict/device/fault/type`, params);
};

// 获取设备分类
export const getEquipmentCategory = () => {
  return axios.get(`${equipment}/select/equipment/category`);
};

// 获取设备台账
export const getEquipmentListByCategory = params => {
  return axios.get(`${equipment}/list/equipment/manager`, {
    params: {
      orgCode: "",
      scrap: 0,
      keyInfo: "",
      category: 1,
      ...params
    }
  });
};
// 获取保养类型
export const getEquipmentMaintainType = () => {
  return axios.get(`${equipment}/dict/device/maintain/type`);
};
// 获取安全附件
export const getEquipmentRelationSafety = (id, params) => {
  return axios.get(`${equipment}/page/equipment/relation/safety`, {
    params: {
      equipmentRecordId: id,
      equipmentId: id,
      page: 1,
      size: 1000,
      ...params
    }
  });
};

// 获取工单
export const getWorkOrderList = (id, params) => {
  return axios.get(`${equipment}/list/equipment/tickets`, {
    params: {
      page: 1,
      size: 10,
      equipmentRecordId: id,
      equipmentId: id,
      ...params
    }
  });
};

// 获取工单详情
export const getTicketById = id => {
  return axios.get(`${equipment}/info/tickets/${id}`);
};

// 获取工单维修记录
export const getTicketMaintenanceById = id => {
  return axios.get(`${equipment}/list/maintenance?ticketId=${id}`);
};

// 获取工单维修记录详情
export const getMaintenanceById = id => {
  return axios.get(`${equipment}/info/maintenance/${id}`);
};

// 获取月份哪天是否有维护数据
export const getEquipmentHasData = (id, params) => {
  return axios
    .get(`${equipment}/list/device/has-data`, {
      params: {
        equipmentRecordId: id,
        ...params
      }
    })
    .then(arr => {
      const obj = {};
      arr.map(a => {
        obj[a.label] = a.value == "1";
      });
      return obj;
    });
};
